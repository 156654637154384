import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import styles from '../styles/about.module.scss'

export default ({ data }) => {
  return (
    <Layout>
      <Head title="About" />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>About</h1>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[0].node.html }}
        />

        <div className={styles.editors}>
          <h2>Editors</h2>
          {
            data.allMarkdownRemark.edges[0].node.frontmatter.editors
              .map( ({name, description}) => {
                return (
                  <>
                    <strong>{name}</strong>&nbsp;{description}
                    <br/>
                  </>
                )
            })
          }
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(about)/" } }
      sort: { fields: frontmatter___editors___name, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            editors {
              description
              name
            }
          }
          html
        }
      }
    }
  }
`
